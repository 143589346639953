import Vue from 'vue'
import App from './App.vue'
import router from "../src/router"
import * as echarts from 'echarts'
import Vant from 'vant';
import { ImagePreview } from 'vant';
import 'vant/lib/index.css';
//rem适配插件
import 'amfe-flexible'
//公共样文件
import "../src/assets/css/utile.css"
import "../src/assets/icon/iconfont.css"
// 导入axios封装文件
import './plugins/axios';
// 导入vuex数据存储对象
import store from './store/index';
import cmblapi from 'cmblapi'

import VConsole from 'vconsole'
if (process.env.NODE_ENV != 'production') {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}

import helper from './unit/helper'
Vue.prototype.$helper = helper;

import filters from "./unit/filters"
// 添加全局filter
Object.keys(filters).map(v => {
    Vue.filter(v, filters[v])
})


import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用
 moment.locale('zh-cn');//需要汉化

Vue.use(Vant);
Vue.use(ImagePreview);
// 引入echarts

Vue.prototype.$echarts = echarts
Vue.prototype.cmblapi = cmblapi


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
