const EqInspection=()=>import("../pages/EqInspection/EqInspection");
const EqInspectionTask=()=>import("../pages/EqInspection/EqInspectTask");
const inspectionCode=()=>import("../pages/EqInspection/inspectionCode");

const EqInspections=[
    {
        path:"/equipment/inspection",
        component:EqInspection,
        meta:{
            title:"设备巡检",
            requireLogin:true,
            keepAlive:true,
        }
    },
    {
        path:"/equipment/task",
        component:EqInspectionTask,
        meta:{
            title:"巡检任务",
            requireLogin:true,
        }
    },
    {
        path:"/equipment/inspectioncode",
        component:inspectionCode,
        meta:{
            title:"扫码巡检",
            requireLogin:true,
        }
    },
];
export default EqInspections;