const clientList = () => import("../pages/client/clientList");
const clientMessage = () => import("../pages/client/clientMessage");
const record = () => import("../pages/client/record");
const newRemind = () => import("../pages/client/newRemind");
const remindDetail = () => import("../pages/client/remindDetail");
const taskManager = () => import("../pages/client/taskManager");
const newFollow= ()=>import("../pages/client/newFollow");
const clientDetailed=()=>import("../pages/client/clientDetailed")
const client = [
    {

        path: '/clientlist',
        component: clientList,
        name:"clientList",
        meta: {
            title: "客户应用",
            // 页面登陆访问权限
            requireLogin: true,
            keepAlive:true,
            isBack: false,
        }
    },
    {

        path: '/newFollow',
        component: newFollow,
        meta: {
            title: "跟进信息",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/client/clientDetailed',
        component: clientDetailed,
        meta: {
            title: "客户详细",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/clientmessage',
        component: clientMessage,
        meta: {
            title: "客户信息",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/record',
        component: record,
        meta: {
            title: "跟进记录",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/newremind',
        component: newRemind,
        meta: {
            title: "提醒信息",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {
    
        path: '/remindDetail',
        component: remindDetail,
        meta: {
            title: "提醒详情",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/taskmanager',
        component: taskManager,
        meta: {
            title: "任务提醒",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },

];
export default client;