// 导入vuex vue
import Vue from 'vue';
import Vuex from 'vuex';
//引入vuex子模块
import parkNotice from "./modules/parkNotice/parkNotice"
import login from "./modules/login/login"


// 注册插件
Vue.use(Vuex);
// 创建数据存储对象
const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        //注册子模块
        parkNotice,
        login
        
    }
});
// 导出数据存储对象
export default store;

