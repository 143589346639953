//巡检任务状态过滤器
export function getXjState (state)  {
    let  stateName = ''
    if (state != null) {
        switch (state) {
            case 0: stateName = "未开始"
                break;
            case 1: stateName = "巡检中"
                break;
            case 2: stateName = "已完成"
                break;
            default: stateName = "全部"
        }
    } else {
        stateName = "未知"
    }
    return stateName;
}

export default { getXjState}