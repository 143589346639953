const myself = () => import("../pages/mySelf/mySelf");
const changePassword = () => import("../pages/mySelf/changePassword");
const mySelf = [
    {

        path: '/myself',
        component: myself,
        meta: {
            title: "个人中心",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
    {

        path: '/changepassword',
        component: changePassword,
        meta: {
            title: "修改密码",
            // 页面登陆访问权限
            requireLogin: true,
        }
    },
]
export default mySelf;