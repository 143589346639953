import axios  from "@/plugins/axios";
export default {
    namespaced: true,
    state(){
        return{
           uesrInfoList:[],
           
        }

    },
    mutations:{
        getuserInfo(state,list){
            state.uesrInfoList=list;

        }
    },
    actions:{
        getuserListActions(context,params){
           
        }

    }
}