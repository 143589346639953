import cmblapi from 'cmblapi';

export default {
	isApp: function() {
		let userAgent = navigator.userAgent
		if(userAgent.indexOf('MPBank') > -1){
			return true
		}else{
			return false
		}
	}
}
