const MeteReading=()=>import("@/pages/WaterMeter/MeteReading");
const MeteReadingRecord=()=>import("@/pages/WaterMeter/MeteReadingRecord");


const WaterMeter=[
    {
        path:"/metereading",
        component:MeteReading,
        meta:{
            title: "水表抄表",
            requireLogin: true,
        }
    },
    {
        path:"/metereading/record",
        component:MeteReadingRecord,
        meta:{
            title: "抄表记录",
            requireLogin: true,
        }
    }
];
export default WaterMeter