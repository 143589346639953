// 导入axios
import Vue from 'vue';
import axios from 'axios';
import { Notify } from 'vant';
// 创建axios实例对象
const _axios = axios.create({
    // 配置数据接口基础域名
    baseURL: process.env.VUE_APP_BASE_API, 
    headers: {
        'Content-Type': 'application/json'
      },
});
Vue.prototype.baseURL = process.env.VUE_APP_BASE_API

// 请求拦截器
_axios.interceptors.request.use( request=>{
    //通过本地存储获取登陆凭证
   if(JSON.parse(localStorage.getItem('loginToken'))){
             const {token} = JSON.parse(localStorage.getItem('loginToken')
        || '{}');
    //  动态添加自定义请求头
    request.headers.Authorization=token;  
   }
  
    
    if(request.url!="/api/File/Uploader/userAvatar"){
        let timestamp = Date.parse(new Date()) / 1000
    if (request.url.indexOf('?') > -1) {
        request.url += `&n=${timestamp}`
    } else {
        request.url += `?n=${timestamp}`
    }
    }
  
    return request;
});
// 响应拦截器
_axios.interceptors.response.use( (response)=>{
    const res = response.data
    let config = response.config
    let url = config.url
    // 特殊接口处理
    if (url.indexOf('/api/oauth/ScanLogin') > -1 || (url.indexOf('Model') > -1 && url.indexOf('Config') > -1)) return res;
    if( url.indexOf('/api/oauth/Login') < 0 && (res.code == 600 || res.code == 601 || res.code == 602)){
         // 600：登录过期,请重新登录  601: 您的帐号在其他地方已登录,被强制踢出 602: Token验证失败
        console.log("登录过期了？");
        Notify({ type: 'danger', message: res.msg });
        localStorage.removeItem("loginToken");
        //location.href='#/login';
        location.replace('#/login')
    }
    return res;
});
// 将axios实例挂载到Vue的原型对象上
Vue.prototype.$axios = _axios;

// 导出axios实例
export default _axios;
