const ComplaintRecord=()=>import("@/pages/complaint/ComplaintRecord");
const ComplaintDetail=()=>import("@/pages/complaint/ComplaintDetail");


const complaint=[
    {
        path:"/complaint/record",
        component: ComplaintRecord,
        meta: {
            title: "投诉记录",
            // 页面登陆访问权限
            requireLogin: true,
            keepAlive:true,
        }
    },
    {
        path:"/complaint/details",
        component: ComplaintDetail,
        meta: {
            title: "投诉详情",
            // 页面登陆访问权限
            requireLogin: true,
        }
    }
]
export default complaint;