const Inspection=()=>import("@/pages/inspectionSecurity/Inspection");
const inspectionTask=()=>import("@/pages/inspectionSecurity/inspectionTask")
const scanCode=()=>import("@/pages/inspectionSecurity/scanCode")

const inspectionSecurity=[
    {
    path:"/patrol/inspection",
    component:Inspection,
    meta:{
        title: "巡更巡检",
        requireLogin: true,
        keepAlive:true,
    }
},
{
    path:"/patrol/inspectiontask",
    component:inspectionTask,
    meta:{
        title: "巡检任务",
        requireLogin: true,
    }
},
{
    path:"/patrol/scancode",
    component:scanCode,
    meta:{
        title: "扫码巡检",
        requireLogin: true,
    }
}
];
export default inspectionSecurity;