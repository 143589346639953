const equipmentMessage=()=>import("@/pages/equipment/equipmentMessage")
const equipmentDetail=()=>import("@/pages/equipment/equipmentDetail")
const equipmentMainten=()=>import("@/pages/equipment/equipmentMainten")

const equipmentmessage=[
    {
        path:"/equipment/message",
        component:equipmentMessage,
        meta:{
            title:"设备信息",
            // 页面登陆访问权限
            requireLogin:true,
             keepAlive:true,
             isBack: false,
        }
    },
    {
        path:"/equipment/detail",
        component:equipmentDetail,
        meta:{
            title:"设备详情",
            // 页面登陆访问权限
            requireLogin:true,
        }
    },
    {
        path:"/equipment/maintenance",
        component:equipmentMainten,
        meta:{
            title:"设备维保",
            // 页面登陆访问权限
            requireLogin:true,
        }
    }
]
export default equipmentmessage