import Vue from "vue";
import VueRouter from "vue-router";
import client from "./client"
import myself from "./myself"
import WaterMeter from "./waterMeter"
import inspectionSecurity from "./inspectionSecurity"
import complaint from "./complaint"
import equipmentmessage from "./equipmentMessage"
import EqInspections from "./EqInspection"
const Login = ()=>import("../pages/Login");
const Home = ()=>import("../pages/Home");
const cost=()=>import("../pages/cost/index");
const workOrder=()=>import("../pages/workOrder/record");
const workDetail=()=>import("../pages/workOrder/detail");
const workEvaluation=()=>import("../pages/workOrder/evaluation");
const workStatistics=()=>import("../pages/workOrder/workStatistics")
const sendOrder=()=>import("../pages/workOrder/sendOrder");
const manage=()=>import("../pages/workOrder/manage");
const inside=()=>import("../pages/inside/inside");
const parkNotice=()=>import("../pages/parkNotice/parkNotice");
const noticeDetailed=()=>import("../pages/parkNotice/noticeDetailed");
const newNotice=()=>import("../pages/parkNotice/newNotice");
const internal=()=>import("../pages/addressList/internal");
const addexternal=()=>import("../pages/addressList/external");
const housTool=()=>import("../pages/housing/housTool");
const housDetail=()=>import("../pages/housing/houseDetail");
const contractMessage=()=>import("../pages/contract/contractMessage");
const contractApprove=()=>import("../pages/contract/contractApprove");
const approveTask=()=>import("../pages/contract/approveTask");
const approveOperrata=()=>import("../pages/contract/approveOperrata");
// 扫码
const scanCodePage=()=>import("../pages/scanCodePage/scanCodePage");
//tabbar任务中心页面
const tabbarTask=()=>import("../pages/tabbar/task")




Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}
 const routes=[
    ...client,
    ...myself,
    ...inspectionSecurity,
    ...WaterMeter,
    ...complaint,
    ...equipmentmessage,
    ...EqInspections,
    {
     // 设置页面访问地址
     path:'/login',
     // 设置页面组件
     component:Login,
     name:"login",
     meta:{
        // 页面登陆访问权限
        requireLogin:false,
        title:"登录"
     }
 },
 {
    // 扫码
    path:'/scanCodePage',
    component:scanCodePage,
    meta:{
        // 页面登陆访问权限
        requireLogin:true,
        title:"扫码"
     }
},
 {
   
    path:'/',
    component:Home,
    name:"home",
     // 自动跳转到的目标页面地址
    meta:{
        // 页面登陆访问权限
        requireLogin:true,
        title:"易园区工作助手",
        keepAlive:true,
        isBack: false, 
     }
},
{
   
    path:'/taskcenter',
    component:tabbarTask,
    meta:{
        // 页面登陆访问权限
        requireLogin:true,
        title:"任务中心",
        keepAlive:true
     }
},
{
   
    path:'/cost',
    component:cost,
    meta:{
        // 页面登陆访问权限
        requireLogin:true,
        title:"费用管理"
     }
},
{
   
    path:'/workrecord',
    component:workOrder,
    meta:{
        title:"工单记录",
        // 页面登陆访问权限
        requireLogin:true,
         keepAlive:true,
         isBack: false,

    }
},
{
   
    path:'/detail',
    component:workDetail,
    meta:{
        title:"工单详细",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/pingjia',
    component:workEvaluation,
    meta:{
        title:"工单评价",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/sendorder',
    component:sendOrder,
    meta:{
        title:"工单派单",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/manage',
    component:manage,
    meta:{
        title:"工单处理",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/workStatistics',
    component:workStatistics,
    meta:{
        title:"工单统计",
        // 页面登陆访问权限
        requireLogin:true,
    }
},

{
   
    path:'/inside',
    component:inside,
    meta:{
        title:"内部报事",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/notice/parkNotice',
    component:parkNotice,
    meta:{
        title:"园区快讯",
        // 页面登陆访问权限
        requireLogin:true,
        keepAlive:true,
    }
},
{
   
    path:'/notice/noticedetailed',
    component:noticeDetailed,
    meta:{
        title:"公告详情",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/notice/newnotice',
    component:newNotice,
    meta:{
        title:"编辑公告",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/addresslist/internal',
    component:internal,
    meta:{
        title:"内部通讯录",
        // 页面登陆访问权限
        requireLogin:true,
        keepAlive:true,
    }
},
{
   
    path:'/addresslist/external',
    component:addexternal,
    meta:{
        title:"外部通讯录",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/housing/housTool',
    component:housTool,
    name:"housTool",
    meta:{
        title:"房源工具",
        // 页面登陆访问权限
        requireLogin:true,
          keepAlive:true,
          isBack: false,
    }
},
{
   
    path:'/housing/housdetail',
    component:housDetail,
    meta:{
        title:"房源信息",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/contractMessage',
    component:contractMessage,
    meta:{
        title:"合同管理",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/approve',
    component:contractApprove,
    meta:{
        title:"合同信息",
        // 页面登陆访问权限
        requireLogin:true,
    }
},
{
   
    path:'/approvetask',
    component:approveTask,
    meta:{
        title:"审批任务",
        // 页面登陆访问权限
        requireLogin:true,
        keepAlive:true,
    }
},
{
   
    path:'/approveoperrata',
    component:approveOperrata,
    meta:{
        title:"审核操作",
        // 页面登陆访问权限
        requireLogin:true,
    }
},


];

 const router=new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    // 引用路由规则数据
    routes:routes,
});
router.beforeEach(function(to,from,next){
    if(to.meta.title){
        document.title=to.meta.title
    }
    if(to.meta.requireLogin){
        const {token}=JSON.parse(localStorage.getItem("loginToken") || "{}");
        if(!token){
             next({path:"/login",replace:true})
        }
    }
    next();
})
export default router;