import axios  from "@/plugins/axios";
export default {
    namespaced: true,
    state(){
        return{
            parkNoticeList:[],
           
        }

    },
    mutations:{
        getParkNoticeInfo(state,list){
            state.parkNoticeList=list

        }
    },
    actions:{
        getParkNoticeListActions(context,params){
            axios.get("/api/SubDev/Basenews",params).then(res=>{
              
                if(res.code==200){
                    console.log(context.state.parkNoticeList);
                    context.commit("getParkNoticeInfo",res.data.list);
                }
            })
        }

    }
}